<template>
    <div class="list">
      <!--  审核结果查询列表 -->
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <div class="contractMenu">
        <!-- <el-button @click="handlePreview " class="derivebtn" type="text" >导出</el-button> -->
      </div>
      <div class="list-table">
        <el-table :data="tableData" ref="tabledeatailData" :border="true" style="width: 100%" show-overflow-tooltip>
          <!-- <el-table-column prop="function" label="序号"  :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column prop="supplierCode" label="供应商编码" :show-overflow-tooltip="true" />
          <el-table-column prop="supplierName" label="供应商名称" :show-overflow-tooltip="true" />
          <el-table-column prop="auditYear" label="审核年度" :show-overflow-tooltip="true" />
          <el-table-column prop="trueScore" label="总分" :show-overflow-tooltip="true">
            <template #default="scope">
              <span>{{compontentSUM (scope.row)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="scoreNum" label="得分" :show-overflow-tooltip="true" />
          <el-table-column prop="scoreDetailList" label="评分细项" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-button  type="text" size="mini" @click="scoreDetailBtn(scope.row)">评分细项</el-button>
            </template>
          </el-table-column>
          <el-table-column label="质量体系审核问题附件" width="170" :show-overflow-tooltip="true">
            <template #default="scope">
              <div class="file-list-wrap">
                <div class="file-item" v-for="(item) in getFileInfoList(scope.row)" :key="item.id">
                  <el-button type="text" class="file-item__label" @click="filePreview(item)">{{ item.name }}</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="质量体系审核改善报告反馈" min-width="170" :show-overflow-tooltip="true">
            <template #default="scope">
              <div class="file-list-wrap">
                <div class="file-item">
                  <el-button type="text" size="mini" class="file-item__file" @click="showUploadFile(scope.row)">上传附件</el-button>
                </div>
                <div class="file-item" v-for="(item) in getFileList(scope.row)" :key="item.id">
                  <el-button type="text" class="file-item__label" @click="filePreview(item)">{{ item.name }}</el-button>
                  <el-button type="text" class="file-item__close" @click="delFile(item.id)" icon="el-icon-close"></el-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="bz" :align="'center'" label="备注" :show-overflow-tooltip="true" />
        </el-table>
      </div>
      <el-dialog title="评分细项及打分" v-model="DialogShow">
        <div>
          <el-table
            show-overflow-tooltip
            ref="Tableccc"
            :data="pingfenlist_xi"
            tooltip-effect="dark"
            style="width: 100%"
            >
            <el-table-column
              prop="detailName"
              label="评分细项"
              min-width="120">
            </el-table-column>
            <el-table-column
              prop="detailTrueScore"
              label="总分"
              min-width="120">
            </el-table-column>
            <el-table-column
              prop="scoreNum"
              label="得分"
              min-width="100">
            </el-table-column>
            <el-table-column
              prop="bz"
              label="备注"
              min-width="200">
            </el-table-column>
          </el-table>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="DialogShow = false">关 闭</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog title="质量体系审核改善报告反馈" v-model="uploadFile.visible" width="500px">
        <el-form>
          <el-form-item label="附件">
            <el-upload
              ref="upload2"
              name="file"
              :auto-upload="true"
              :action= 'uploadFile.postUrl'
              :on-remove="fileRemove"
              :on-success="fileSuccess"
              :on-preview="filePreview"
              :headers="uploadFile.tokenHeader"
              :file-list="uploadFile.upReturnData"
            >
              <el-button size="mini" type="primary" plain>+上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="mini" @click="uploadFile.visible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submitFile">确 定</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table> -->
    </div>
</template>

<script>
import { request, baseURL } from '@/assets/js/http.js'
// import { MIME } from '@/assets/js/mime.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, menudata, tableData } from './js/supAuditResultInquiry.js'
import { fileHandle } from '@/assets/js/FileAction.js'
// import qs from 'qs'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'AuditResultInquiry',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 340) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      DialogShow: false,
      pingfenlist_xi: [],
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      menudata: menudata,
      tableData: tableData,
      searchdata: SearchData,
      paginationData: {
      },
      pageNum: 1,
      total: 0,
      pageSize: pageSize,
      uploadFile: {
        visible: false,
        current: '',
        postUrl: baseURL + '/api/file/m/file/uploadToServer',
        upReturnData: [],
        tokenHeader: {
          token: localStorage.getItem('token')
        }
      }
    }
  },
  created () {
    this.auditScoreList()
  },
  mounted () {
  },
  methods: {
    compontentSUM (rowdata) {
      let sum = 0
      for (const row of rowdata.scoreList) {
        sum = sum + (row.trueScore ? parseFloat(row.trueScore) : 0)
      }
      return sum
    },
    scoreDetailBtn (data) {
      console.log(data)
      this.pingfenlist_xi = []
      for (const row of data.scoreList) {
        for (const row1 of row.scoreDetailList) {
          this.pingfenlist_xi.push(row1)
        }
      }
      this.DialogShow = true
    },
    // scoreDetailBtn (data) { // 评分细项
    //   request(`/api/quality/scoreDetail/getByScoreId/${data.id}`, 'get').then((res) => {
    //     console.log(res)
    //     if (res.code === '200') {
    //       const ccc = res.data
    //       console.log(ccc)
    //       this.pingfenlist_xi = ccc
    //     }
    //   })
    //   this.DialogShow = true
    // },
    auditScoreList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 340) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        supplier: this.childData.searchData.supplier,
        auditYear: this.childData.searchData.auditYear
      }
      request('/api/quality/auditScore/list?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + pageSize, 'GET', obj).then((res) => {
        if (res.code === '200') {
          console.log(res)
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'DraftaContract' }, query: { id: data.id } })
      } else if (clickBtn.operationButton === 'invalid') { // 审批驳回以后,显示作废按钮
      }
    },
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: 'DraftaContract' } })
    },
    // handlePreview () {
    //   var file = { name: '审核结果.xls' }
    //   exportForm('/api/pact/contract/poiOperate?' + qs.stringify(this.searchdata), 'post').then(
    //     (response) => {
    //       var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    //       var mime = 'application/octet-stream'
    //       if (MIME[fileExtension]) {
    //         mime = MIME[fileExtension]
    //       }
    //       const aBlob = new Blob([response.data], { type: mime })
    //       this.downloadByBlob(aBlob, file.name, mime)
    //     })
    // },
    pageChange (data) {
      // console.log(data);
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      this.auditScoreList()
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      data.type = 0
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.strEndDate = this.dateFormate(data.createDate[1])
        this.searchdata.strStartDate = this.dateFormate(data.createDate[0])
      }
      this.auditScoreList()
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    /**
     * 上传附件
     * 修改内容：新增上传附件内容
     * 修改日期：2023-08-07
     * 作者：高天滨
     */
    showUploadFile (row) {
      console.log(row)
      this.uploadFile.visible = true
      this.uploadFile.current = row
      this.uploadFile.upReturnData = []
    },
    filePreview (file) {
      console.log(file)
      fileHandle.handlePreview(file, true)
    },
    fileRemove (file, list) {
      console.log(file)
      this.uploadFile.upReturnData = list
      request('/api/file/m/file/delFile/' + file.id, 'get')
      this.auditScoreList()
    },
    fileSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(item => {
          this.uploadFile.upReturnData.push(item)
        })
      }
    },
    submitFile () {
      if (this.uploadFile.upReturnData.length < 1) {
        this.$message.error('请先选择附件')
      }
      const fileId = []
      const fileSupplierInfo = this.uploadFile.current.fileSupplierInfo || []
      fileSupplierInfo.forEach(item => {
        if (item) {
          fileId.push(item.id)
        }
      })
      this.uploadFile.upReturnData.forEach(item => {
        fileId.push(item.id)
      })
      request(`/api/quality/auditScore/uploadSupplierFile?id=${this.uploadFile.current.id}&fileId=${fileId.join(',')}`, 'post').then(res => {
        if (res.code === '200') {
          this.auditScoreList()
          this.uploadFile.visible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getFileList (row) {
      if (row.fileSupplierInfo) {
        return row.fileSupplierInfo.filter(item => item !== null)
      } else {
        return []
      }
    },
    delFile (delid) {
      this.$confirm('是否删除？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/file/m/file/delFile/' + delid, 'get').then((res) => {
          if (res.code === '200') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.auditScoreList()
          }
        })
      }).catch(() => {
        // this.$message({
        //   message: '操作取消',
        //   type: 'info'
        // })
      })
    },
    getFileInfoList (row) {
      if (row.fileInfo) {
        return row.fileInfo.filter(item => item !== null)
      } else {
        return []
      }
    }
  }
}
</script>
<style scoped lang="scss">
.list{
  &-table {
    padding: 0 30px;
    .file-list-wrap {
      display: flex;
      align-items: center;
      // flex-direction: column;
      .file-item {
        display:flex;
        align-items:center;
        // width: calc( 100% - 20px);
        // justify-content: center;
        padding-bottom: 5px;
        &__file {
          margin-right: 5px;
        }
        &__label {
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          width: calc( 100% - 20px);
          min-height:0px;
          padding-top:0;
          padding-bottom:0;
        }
        &__close {
          margin-left: 0px;
          padding: 0;
          min-height: 0;
        }
      }
    }
  }
    :deep(.el-table) {
    margin-top: 20px;
    // padding: 0 30px;
    // margin: 20px 30px;
    .el-table__header-wrapper{
      table{
        thead{
          tr{
            th{
              background: #ededed;
            }
          }
        }
      }
    }
}
}
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  right: 35px;
  top: 8px;
  font-size: 16px;
 }
}
</style>
